<template>
  <v-container fluid>
    <div class="d-flex mb-3" v-if="all">
      <v-alert type="info" class="ma-0" dense text> Eventos pendientes </v-alert>
    </div>

    <div class="d-flex mb-2">
      <v-btn small color="primary" @click.stop="getEventos">
        <v-icon left small>mdi-refresh</v-icon>
        Recargar</v-btn
      >
    </div>

    <v-card>
      <v-data-table
        fixed-header
        @current-items="eventosExp = $event"
        :items="eventos"
        :headers="eventosHeaders"
        class="fixed-checkbox"
        item-key="idEvento"
        :single-select="false"
        show-select
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
            itemsPerPageOptions: [10, 25, 50, -1],
        }"
        checkbox-color="secondary"
        @contextmenu:row="contextMenuEvento"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="eventos"
            v-model="inlineFilters"
          ></table-filters>
        </template>
        
        <!-- <template v-slot:item.entrada="{ item }">
            <span v-text="parseDate(item.fechaReserva)"></span>
          </template> -->

        <template v-slot:item.tipoEvento="{ item }">
          <v-chip
            class="v-chip--active font-weight-bold"
            outlined
            label
            :color="item.colorTipoEvento"
          >
            <v-icon left>{{ item.iconTipoEvento }}</v-icon>
            {{ item.tipoEvento }}
          </v-chip>
        </template>

        <template v-slot:item.estado="{ item }">
          <v-chip
            class="v-chip--active font-weight-bold"
            outlined
            label
            :color="item.colorEstadoEvento"
            @click.stop="cambioEstado = item.idEvento"
          >
            <v-icon left>{{ item.iconEstadoEvento }}</v-icon>
            {{ item.estado }}
          </v-chip>
        </template>

        <template v-slot:item.acciones="{ item }">
          <div class="d-flex align-center">
            <v-btn
              :disabled="!item.editable"
              rounded
              small
              color="primary"
              :to="`/eventos/${item.idEvento}`"
            >
              <v-icon left>mdi-pencil</v-icon>
              Editar
            </v-btn>
            <!-- <v-btn
              :disabled="item.estado != 'Terminado'"
              rounded
              color="primary"
							class="ml-1"
              :to="`/eventos/${item.idEvento}/informe`"
							v-if="$root.acceso('CREAR_INFORME')"
            >
              <v-icon left>mdi-file-sign</v-icon>
              Generar informe
            </v-btn> -->
          </div>
        </template>

        <template v-slot:item.tipoBoda="{ item }">
          <v-chip
            class="v-chip--active"
            outlined
            label
            :color="item.colorTipoBoda"
            v-if="item.tipoBoda"
          >
            {{ item.tipoBoda }}
          </v-chip>
        </template>

        <template v-slot:item.datos.tipoCelebracion="{ item }">
          <v-chip
            class="v-chip--active"
            outlined
            label
            v-if="item.datos.tipoCelebracion"
          >
            <v-icon left>mdi-party-popper</v-icon>
            {{ item.datos.tipoCelebracion }}
          </v-chip>
        </template>

        <template v-slot:item.nombreEspacio="{ item }">
          <v-chip
            class="v-chip--active"
            outlined
            label
            v-if="item.nombreEspacio"
          >
            <v-icon left>mdi-map-marker</v-icon>
            {{ item.nombreEspacio }}
          </v-chip>
        </template>

        <template v-slot:item.nombreSalon="{ item }">
          <div v-if="item.nombreSalon" class="d-flex" style="gap: 3px">
            <v-chip
              class="v-chip--active"
              outlined
              label
              v-for="sal in item.nombreSalon.split(',')"
              :key="sal"
            >
              <v-icon left>mdi-map-marker-outline</v-icon>
              {{ sal }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.nombreVia="{ item }">
          <v-chip v-if="item.nombreVia" outlined label small>
            {{ item.nombreVia }}
          </v-chip>
        </template>

        <template v-slot:item.nAdultos="{ item }">
          <v-chip small v-if="item.nAdultos > 0" label>
            <v-icon left small>mdi-human-male</v-icon>
            {{ (item.nAdultos || 0) }}
          </v-chip>
          <v-icon small v-else>mdi-minus</v-icon>
        </template>

        <template v-slot:item.nNinos="{ item }">
          <v-chip small v-if="item.nNinos > 0" label>
            <v-icon left small>mdi-human-child</v-icon>
            {{ (item.nNinos || 0) }}
          </v-chip>
          <v-icon small v-else>mdi-minus</v-icon>
        </template>

        <template v-slot:item.tcos="{ item }">
          <v-chip small v-if="item.tcos > 0" label>
            <v-icon left small>mdi-human-male-female-child</v-icon>
            {{ item.tcos }}
          </v-chip>
          <v-icon small v-else>mdi-minus</v-icon>
        </template>

        <template v-slot:item.email="{ item }">
          <v-chip small v-if="item.cliente" label>
            {{ item.cliente }}
          </v-chip>
          <v-icon small v-else>mdi-minus</v-icon>
        </template>

        <template v-slot:item.telefono="{ item }">
          <v-chip small v-if="item.clientes[0]" label>
            {{ item.clientes[0].telefono }}
          </v-chip>
          <v-icon small v-else>mdi-minus</v-icon>
        </template>
        <template v-slot:item.enlaceCivil="{ item }">
          <v-icon
            small
            v-if="item.enlaceCivil !== undefined && !item.enlaceCivil"
            color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon
            small
            v-else-if="item.enlaceCivil !== undefined && item.enlaceCivil"
            color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.datos.fechaInicioEvento="{ item }">
          <v-chip>
            <v-icon left>mdi-calendar</v-icon>
            <span v-text="parseDate(item.datos.fechaInicioEvento)"></span>
          </v-chip>
        </template>

        <template v-slot:item.fechaReserva="{ item }">
          <v-chip>
            <v-icon left>mdi-calendar</v-icon>
            <span v-text="parseDate(item.fechaReserva, false, true, false)"></span>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

      <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
        <v-btn color="primary" fab @click="exportar" large class="me-4">
          <v-icon>mdi-microsoft-excel</v-icon>
          <!-- Añadir evento -->
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn color="primary" fab v-if="$root.acceso('CREAR_EVENTO')" to="/eventos/nuevo" large>
          <v-icon>mdi-plus</v-icon>
          <!-- Añadir evento -->
        </v-btn>
      </div>

    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>{{ detailsId }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="detailsId = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <detalles-evento :key="detailsId" :idEvento="detailsId"></detalles-evento>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      :value="cambioEstado"
      @input="(v) => (v === false ? (cambioEstado = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>Cambiar estado {{ cambioEstado }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="cambioEstado = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <cambio-estado
        @close="cambioEstado = null"
        @reload="getEventos"
        :key="cambioEstado"
        :idEvento="cambioEstado"
      ></cambio-estado>
    </v-dialog>

    <component :is="contextMenuComp" />
  </v-container>
</template>

<script>
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";
import contextMenu from "../services/contextMenu.js";
import { parseDate, perColumnFilter } from "@/utils";

export default {
  components: {
    CambioEstado: () => import("../components/CambiarEstado.vue"),
    DetallesEvento: () => import("./Evento.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: {
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inlineFilters: { idEvento: this.$route.query.filter ? `=${this.$route.query.filter}` : null },
      tipo: null,
      eventos: [],
      eventosExp: [],
      loading: false,
      contextMenuComp: null,
      detailsId: null,
      cambioEstado: null,
      tipos: [],
    };
  },
  computed: {
    eventosHeaders() {
      return [
        { text: "Acciones", value: "acciones", filterable: false, sortable: false,},
        { text: "Entrada", value: "fechaReserva", dataType: "date",},
        { text: "Tipo", value: "tipoEvento", dataType: "select" },
        { text: "Fecha", value: "datos.fechaInicioEvento", dataType: "date",},
        { text: "Horario", value: "nombreHorario", dataType: "select" },
        { text: "Cliente", value: "datos.tipoCelebracion", tab: [1, 2, 3, 4],},
        { text: "Nombre", value: "cliente.nombreCompleto" },
        { text: "Espacio", value: "nombreEspacio", dataType: "select" },
        { text: "Salon", value: "nombreSalon", dataType: "select" },
        { text: "Adultos", value: "datos.nAdultos" },
        { text: "Niños", value: "datos.nNinos" },
        { text: "Total", value: "datos.tcos" },
        { text: "Teléfono", value: "cliente.telefono" },
        { text: "Email", value: "cliente.email" },
        { text: "Estado", value: "estado", dataType: "select" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso)) &&
            (h.tab == null ||
              [h.tab].flat().includes(this.tipo * 1) ||
              !this.tipo)
        )
        .map((header) => ({
          class: "text-no-wrap sticky-header",
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  watch: {
    tipo(n) {
      this.getEventos(n);
    },
  },
  mounted() {
    this.getEventos();
  },
  methods: {
    exportar() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('Perdidas');
      sheet.columns = [
        { header: "Nº", key: "n", width: 10 },
        { header: "TIPO", key: "tipo", width: 10 },
        { header: "ESTADO", key: "estado", width: 20 },
        { header: "F.INICIO", key: "fecha", width: 20 },
        { header: "Hº", key: "horario", width: 20 },
        { header: "NOMBRE COMPLETO", key: "nombre", width: 20 },
        { header: "TIPO CELEBRACIÓN", key: "tipoCeleb", width: 20 },
        { header: "ESPACIO", key: "espacio", width: 20 },
        { header: "SALÓN", key: "salon", width: 20 },
        { header: "ADULTOS", key: "ad", width: 20 },
        { header: "NIÑOS", key: "ni", width: 20 },
        { header: "TOTAL", key: "tot", width: 20 }
      ];
      for (let i = 0; i < this.eventosExp.length; i++) {
        sheet.addRow([(i + 1),
        this.eventosExp[i].tipoEvento,
        this.eventosExp[i].estado,
        this.eventosExp[i].datos.fechaInicioEvento.split(" ")[0],
        this.eventosExp[i].nombreHorario,
        this.eventosExp[i].cliente.nombreCompleto,
        this.eventosExp[i].datos.tipoCelebracion,
        this.eventosExp[i].nombreEspacio,
        this.eventosExp[i].nombreSalon,
        this.eventosExp[i].datos.nAdultos,
        this.eventosExp[i].datos.nNinos,
        this.eventosExp[i].datos.tc])
      }
      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${"PENDIENTES - EXPORTADO EL " + (new Date().toLocaleDateString('default', { weekday: 'short' }).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear() + ".xlsx"}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
    },
    contextMenuEvento(event, item) {
      contextMenu(event, item.item, this, item.isSelected, item.select);
    },
    parseDate,
    async getEventos() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: "eventos/pendientes",
        params: { tipo: this.tipo || null, all: this.all },
      });

      this.eventos = data.map((m) => {
        m.datos = JSON.parse(m.datos);
        m.datos.tipoCelebracion = (m.datos.novios || m.datos.nino || m.datos.tipoCelebracion)
        m.cliente = m.datos.clientes[0];
        m.datos.tcos = (m.datos.nNinos * 1 || 0) + (m.datos.nAdultos * 1 || 0);
        return m;
      });
      this.loading = false;
    },
  },
};
</script>